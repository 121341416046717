
html{
	background-color: #000;
	font-family: 'Haboro';
}


.vimeo-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	overflow: hidden;
}  
// .vimeo-wrapper:before {
// 	background-image: url("/src/img/Nexum_Xmas-Card-coin.png");
// 	background-repeat: no-repeat;
// 	width: 30%;
// 	max-width: 370px;
// 	height: 100%;
// 	position: absolute;
// 	left: 0;
// 	top: 0;
// 	content: "";
// 	z-index: 100;
// 	pointer-events: none;
// 	background-size: contain;
// }  

div.responsive-device{
	display: none;
	@media screen and (max-width: 1024px) and (orientation: portrait) {
		display: block;
		height: 100vh;
		width: 100vw;
		background-image: url('/src/img/rotate_tablet.svg');
		background-repeat: no-repeat;
		background-color: #ffffff;
		position: absolute;
		z-index: 10;
		background-size: 150px;
		background-position: 50%;
		z-index: 10000;
	}

	@media screen and (max-width: 768px) and (min-height: 1024px) {
		display: block;
		height: 100vh;
		width: 100vw;
		background-image: url('/src/img/rotate_tablet.svg');
		background-repeat: no-repeat;
		background-color: #ffffff;
		position: absolute;
		z-index: 10;
		background-size: 150px;
		background-position: 50%;
		z-index: 10000;
	}
	@media screen and (max-width: 480px) and (orientation: portrait){
		display: block;
		height: 100vh;
		width: 100vw;
		background-image: url('/src/img/rotate_mobile.svg');
		background-repeat: no-repeat;
		background-color: #ffffff;
		position: absolute;
		z-index: 10;
		background-size: 150px;
		background-position: 50%;
		z-index: 10000;
	}
}


.vimeo-wrapper iframe {
	width: 100vw;
	height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
	min-height: 100vh;
	min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

p {
  display: inline-block;
  margin-left: 10px;
}

.content-player{
	display: none;
	position: absolute;
    bottom: 20px;
	left: 20px;

	@media screen and (max-height: 480px){
		display: block;
	}
}
button {
	background: none;
	background-image: url('/src/img/icon_play.svg');
	cursor: pointer;
	font-weight: 700;
	font-size: 13px;
	border: 0;
	height: 40px;
	width: 40px;
	text-indent: -10000000%;
	background-size: 100%;
	margin-left: 0.75em;
	z-index: 10;
	
	&.dn{
		display: none;
	}
	

	&.pause{
		display: none;
	}
	&.db{
		display: block;
	}
	&:nth-child(2){
		
		background-image: url('/src/img/icon_pause.svg');
		left: 0;
	
	}
  }

  ul{
	font-size: 18px;
    display: inline-block;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 30px;
	right: 30px;
	text-transform: uppercase;
	li{
		display: inline-block;
		margin: 0 10px;

		&.active{
			border-bottom: 1px solid white;
		}
		a{
			color: #fff;
			text-decoration: none;
			opacity: .8;
			transition: .3s ease-in;
			&:hover{
				opacity: 1;

			}
		}
	}
  }