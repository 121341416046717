@font-face {
	font-family: 'adjust';
	src:url('../fonts/adjust-font.eot');
	src:url('../fonts/adjust-font.eot?#iefix') format('embedded-opentype'),
		url('../fonts/adjust-font.woff') format('woff'),
		url('../fonts/adjust-font.ttf') format('truetype'),
		url('../fonts/adjust-font.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Haboro';
	src:url('../fonts/HaboroSoft/HaboroSoftNormBold.ttf') format('truetype');
	font-weight: 800;
}


@font-face {
	font-family: 'Haboro';
	src:url('../fonts/HaboroSoft/HaboroSoftNormRegular.woff') format('woff'),
		url('../fonts/HaboroSoft/HaboroSoftNormRegular.ttf') format('truetype');
	font-weight: 400;
}